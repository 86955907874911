
import {defineComponent} from "vue";
import CodeHighlighter2 from "@/view/content/highlighters/CodeHighlighter2.vue";

export default defineComponent({
  name: "overview",
  components: {
    CodeHighlighter2,
  },
  setup() {
    const html = `<button type="button" class="btn btn-primary rotate" id="kt_button_1">
    90 deg <span class="svg-icon-2 rotate-90"><svg></svg></span>
</button>`;

    const js = `// Element to indecate
var button = document.querySelector("#kt_button_1");

// Handle button click event
button.addEventListener("click", function() {
    button.classList.toggle("active");
});`;

    const click = (event) => {
      const button = event.currentTarget;
      button.classList.toggle("active");
    };

    return {
      html,
      js,
      click,
    };
  },
});
